<style lang="scss">
.card {
  .card-body {
    padding: 1.25rem;
    .btn-right {
      float: right;
    }
    .cleft {
      float: left;
    }
  }
}
</style>
<template>
  <div
    class="bottom-nav"
    style="position: fixed; bottom: 0; width: calc(100vw)"
  >
    <div class="card">
      <div class="card-body">
        <div class="cleft"></div>
        <div class="mt-1">
          <button
            class="btn btn-success btn-right"
            @click="next()"
            style="width:115px"
            ref="kt_submit"
          >
            {{ btnname }}
            <i style="margin-left: 5px;" :class="'fa ' + nextbtnicon"></i>
          </button>
          <button class="btn btn-dark mr-2 btn-right" @click="back()">
            <i class="fa fa-arrow-left"></i> {{ backname }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { isMobile } from "mobile-device-detect";
export default {
  props: {
    btnname: {
      type: String,
      default: "Button"
    },
    nextbtnicon: {
      type: String,
      default: "fa-arrow-right"
    },
    backname: {
      type: String,
      default: "Back"
    }
  },
  methods: {
    next() {
      this.$emit("next");
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>
