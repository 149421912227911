<style lang="scss">
#citizen-status {
  .color {
    color: #1c1465;
  }
  .i-large {
    font-size: 35px;
  }
  .text-upper {
    text-transform: uppercase;
  }
  .citizen-list {
    padding: 15px 10px 0 0;
    text-align: center;
    cursor: pointer;
    position: relative;

    .active-icon {
      border: 2px solid;
      border-radius: 50%;
      background: white;
      color: #1bc5bd;
      position: absolute;
      left: 5px;
      font-size: 16px;
      padding: 8px;
      top: 5px;
    }
  }
  .card {
    @media (max-width: 1351px) {
      height: 150px;
    }

    @media (max-width: 1201px) {
      height: 170px;
    }

    @media (max-width: 990px) {
      height: 150px;
    }

    @media (max-width: 988px) {
      height: 170px;
    }

    @media (max-width: 928px) {
      height: 170px;
    }

    @media (max-width: 768px) {
      height: 120px;
    }

    &:hover {
      background: rgba(208, 206, 229, 0.6784313725490196);
      border: 1px solid #211a68;
    }
    &.active {
      border: 1px solid rgb(33, 26, 104);
      background: rgba(208, 206, 229, 0.68);
    }
  }
}
</style>
<template>
  <div id="citizen-status">
    <h5>Please select your Citizenship Status</h5>
    <hr />
    <div class="row mt-3">
      <div class="col-md-4" v-for="(item, index) in citizens" :key="index">
        <div
          class="card"
          :class="item.name == getCitizenTitle ? 'active' : ''"
          @click="navigate(item.name)"
        >
          <div class="citizen-list">
            <i
              class="fa fa-check active-icon"
              v-if="item.name == getCitizenTitle"
            ></i>
            <i class="color i-large" :class="item.icon"></i>
            <h4 class="text-upper mt-3">{{ item.name }}</h4>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_CITIZEN_TITLE } from "@/core/services/store/mutations.type";
export default {
  data() {
    return {
      citizens: [
        {
          name: "us citizen",
          description: "I am a United States Citizen",
          icon: "fas fa-flag-usa"
        },
        {
          name: "lawful permanent resident",
          description: "I am a Lawful Permanent Resident of the United States.",
          icon: "fas fa-address-card"
        },
        {
          name: "other",
          description: "I am not a US Citizen or Lawful Permanent Resident.",
          icon: "fas fa-globe"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getCitizenTitle"])
  },
  created() {
    if (this.getCitizenTitle == "") {
      this.$store.commit(SET_CITIZEN_TITLE, "us citizen");
    }
  },
  methods: {
    navigate: function(val) {
      // this.getCitizenTitle = val;
      this.$store.commit(SET_CITIZEN_TITLE, val);
    }
  }
};
</script>
