<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body uscitizen">
          <section class="row">
            <div
              class="col-md-12"
              style="padding:15px"
              v-html="getSettingNote.fbi_check_disclosure_note"
            ></div>
          </section>
        </div>
        <div
          class="card-footer"
          style="padding: 15px;position: sticky;bottom: 70px;"
        >
          <div class="form-group" style="display:inline-flex">
            <input
              type="checkbox"
              @change="acceptTerms"
              v-model="check1"
              class="mr-3"
              id="check-accept"
            />
            <label
              for="check-accept"
              style="
    font-size: 14px;
    display: inline;
    margin-top: 10px;
"
            >
              I accept these terms and conditions.</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      check1: false
    };
  },
  computed: {
    ...mapGetters(["getSettingNote"])
  },
  methods: {
    acceptTerms() {
      this.$emit("term", this.check1);
    }
  }
};
</script>
