<style lang="scss">
#f-citizen {
  .header-small {
    font-size: 14px;
  }
}
.font-bold {
  font-weight: bold;
}
</style>

<template>
  <section class="content d-flex flex-column flex-column-fluid" id="f-citizen">
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid mb-25">
        <div class="row">
          <div
            class="col-12 text-center"
            v-if="routeName == 'client.appointment.citizenship'"
          >
            <h1>FBI BACKGROUND CHECK</h1>
            <p class="header-small" v-if="step == 1">
              Citizenship Requirements
            </p>
          </div>
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <v-citizen
              v-if="step == 1 && routeName == 'client.appointment.citizenship'"
            ></v-citizen>
            <v-disclaimer
              v-if="step == 2 && routeName == 'client.appointment.citizenship'"
              v-on:term="tiggerTerm"
            ></v-disclaimer>

            <v-f-disclaimer
              v-if="step == 1 && routeName == 'client.appointment.disclosure'"
              v-on:term="tiggerTerm"
            ></v-f-disclaimer>
          </div>
        </div>
      </div>
      <v-foooter
        v-on:next="nextStep"
        v-on:back="back"
        :btnname="'Next'"
        :backname="'Back'"
      ></v-foooter>
    </div>
  </section>
</template>

<script>
import vFoooter from "./foooter.vue";
import vCitizen from "./status.vue";
import vDisclaimer from "./disclaimer.vue";
import vFDisclaimer from "./fndisclamier.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    vFoooter,
    vCitizen,
    vDisclaimer,
    "v-f-disclaimer": vFDisclaimer
  },
  data() {
    return {
      step: 1,
      check1: false,
      routeName: ""
    };
  },
  computed: {
    ...mapGetters(["getServiceId", "getfServiceSlug"])
  },
  created() {
    this.routeName = this.$route.name;

    if (this.getServiceId == 0) {
      // this.$toastr.e("You have not select service");
      this.$router.push({
        name: "frontend.home",
        params: { slug: this.$route.params.client_slug }
      });
    }
  },
  methods: {
    tiggerTerm(flag) {
      this.check1 = flag;
    },
    back() {
      if (this.step == 1) {
        this.$router.push({
          name: "frontend.agent.locations",
          params: { slug: this.getfServiceSlug }
        });
      } else if (this.step == 2) {
        this.step = 1;
      }
    },
    nextStep() {
      if (this.step == 1) {
        if (this.routeName == "client.appointment.citizenship") {
          this.step = 2;
        } else {
          if (this.check1) {
            var id_ = this.$route.params.id;
            this.$router.push({
              name: "frontend.booknow",
              params: { id: id_ }
            });
          } else {
            this.$toastr.e("Plase accept both terms and conditions");
          }
        }
      } else if (this.step == 2) {
        if (this.check1) {
          var id = this.$route.params.id;
          this.$router.push({ name: "frontend.booknow", params: { id: id } });
        } else {
          this.$toastr.e("Plase accept both terms and conditions");
        }
      }
    }
  }
};
</script>
